import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../components/container"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Cta from "../components/cta"
import Button from "../components/button"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "resources/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      resourceTips: file(
        relativePath: { eq: "resources/tips-for-talking.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      resourceBrochure: file(
        relativePath: { eq: "resources/patient-brochure.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      resourcePrescribing: file(
        relativePath: { eq: "resources/full-prescribing-information.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      ctaImage: file(relativePath: { eq: "resources/cta.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      smallImage: file(relativePath: { eq: "backgrounds/cta-small-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(relativePath: { eq: "backgrounds/cta-large-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Layout activeLinkIndex={3}>
      <Hero styles={tw`mb-16 pb-20 lg:(pt-24 mb-20)`}>
        <Container>
          <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
            <div tw="col-span-full lg:col-span-7">
              <h1 tw="uppercase font-light text-[32px] [line-height: 36px] mb-4 lg:(text-43 mb-2 w-full) xl:(text-6xl mb-6 [line-height: 1.4])">
                Resources{" "}
                <span tw="font-extrabold">
                  <br tw="lg:hidden" />
                  Made
                  <br tw="hidden lg:block" /> For <br tw="lg:hidden" />
                  You.
                </span>
              </h1>
              <p tw="font-normal text-base w-full lg:(text-base pr-6 w-full) xl:(text-21 pr-20)">
                Looking for more information
                <br tw="lg:hidden" /> about LIVTENCITY? These
                <br tw="lg:hidden" /> materials are a good
                <br tw="lg:hidden" /> place to start.
              </p>
            </div>
            <div tw="col-span-full lg:(relative col-start-8 col-span-5)">
              <GatsbyImage
                image={getImage(data.heroImage)}
                css={[
                  tw`absolute bottom-[-50px] right-[-60px] w-[280px] lg:(block w-[290px] right-[-30px] bottom-[unset] top-[-60px]) xl:(w-[390px] right-[0px])`,
                ]}
                alt=""
              />
            </div>
          </div>
        </Container>
      </Hero>

      <section tw="pb-12 lg:pb-16">
        <Container>
          <div tw="grid grid-cols-4 gap-x-7 mb-16 lg:(grid-cols-12 mb-12)">
            <div tw="hidden lg:(block col-span-4 pl-10)">
              <GatsbyImage
                image={getImage(data.resourceTips)}
                alt=""
                tw="lg:w-[200px]"
              />
            </div>
            <div tw="col-span-full lg:col-span-8">
              <div tw="flex mb-4">
                <GatsbyImage
                  image={getImage(data.resourceTips)}
                  alt=""
                  tw="w-[130px] mr-6 lg:hidden"
                />
                <h2 tw="w-full font-bold text-2xl mt-2 [line-height: 30px] lg:(mt-0 font-bold text-xl mb-4) xl:(text-27)">
                  Tips for Talking With Your Transplant Team
                </h2>
              </div>
              <p tw="font-light mb-4 lg:(text-base mb-6 w-full) xl:(text-xl)">
                Get useful tips for having helpful, personalized conversations
                with your transplant team.
              </p>
              <Button
                link="/pdf/tips-for-talking-with-your-transplant-team.pdf"
                external
                buttonClass="gtm-download"
                documentName="DoctorDiscussionGuide"
              >
                Download
              </Button>
            </div>
          </div>
          <div tw="grid grid-cols-4 gap-x-7 mb-16 lg:(grid-cols-12 mb-12)">
            <div tw="hidden lg:(block col-span-4 pl-10)">
              <GatsbyImage
                image={getImage(data.resourceBrochure)}
                alt=""
                tw="lg:w-[200px]"
              />
            </div>
            <div tw="col-span-full lg:col-span-8">
              <div tw="flex mb-4">
                <GatsbyImage
                  image={getImage(data.resourceBrochure)}
                  alt=""
                  tw="w-[130px] mr-6 lg:hidden"
                />
                <h2 tw="w-full font-bold text-2xl mt-2 [line-height: 30px] lg:(mt-0 font-bold text-xl mb-4) xl:(text-27)">
                  LIVTENCITY Patient Brochure
                </h2>
              </div>
              <p tw="font-light mb-4 lg:(text-base mb-6 w-full) xl:(text-xl)">
                This brochure helps you and your loved ones learn more about CMV
                after transplant and LIVTENCITY.
              </p>
              <div tw="block mb-4 lg:(mb-0 mr-12 inline-block)">
                <p tw="block italic font-light text-center mb-1 lg:text-sm xl:text-[18px]">
                  (English)
                </p>
                <Button
                  link="https://content.takeda.com/?contenttype=GEN&product=LIV&language=ENG&country=USA&documentnumber=9"
                  external
                  buttonClass="gtm-download"
                  documentName="PatientBrochureEN"
                >
                  Download
                </Button>
              </div>
              <div tw="block lg:inline-block">
                <p tw="block italic font-light text-center mb-1 lg:text-sm xl:text-[18px]">
                  (Espa&ntilde;ol)
                </p>
                <Button
                  link="https://assets.takeda.com/content/dam/takeda/imported/livtencity/pdfs/ESP-Guide.pdf"
                  external
                  buttonClass="gtm-download"
                  documentName="PatientBrochureES"
                >
                  Descargar
                </Button>
              </div>
            </div>
          </div>
          <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
            <div tw="hidden lg:(block col-span-4 pl-10)">
              <GatsbyImage
                image={getImage(data.resourcePrescribing)}
                alt=""
                tw="lg:w-[200px]"
              />
            </div>
            <div tw="col-span-full lg:col-span-8">
              <div tw="flex mb-4">
                <GatsbyImage
                  image={getImage(data.resourcePrescribing)}
                  alt=""
                  tw="w-[130px] mr-6 lg:hidden"
                />
                <h2 tw="w-full font-bold text-2xl mt-2 [line-height: 30px] lg:(mt-0 font-bold text-xl mb-4) xl:(text-27)">
                  Full Prescribing Information
                </h2>
              </div>
              <p tw="font-light mb-4 lg:(text-base mb-6 w-full) xl:(text-xl)">
                You can explore the Full Prescribing Information for LIVTENCITY,
              </p>
              <div tw="block mb-4 lg:(mb-0 mr-12 inline-block)">
                <p tw="block italic font-light text-center mb-1 lg:text-sm xl:text-[18px]">
                  (English)
                </p>
                <Button
                  link="https://content.takeda.com/?contenttype=pi&product=liv&language=eng&country=usa&documentnumber=1"
                  external
                  buttonClass="gtm-download"
                  documentName="PrescribingInformation"
                  click="CTAClick"
                >
                  Download
                </Button>
              </div>
              <div tw="block lg:inline-block">
                <p tw="block italic font-light text-center mb-1 lg:text-sm xl:text-[18px]">
                  (Espa&ntilde;ol)
                </p>
                <Button
                  link="https://content.takeda.com/?contenttype=pi&product=liv&language=esp&country=usa&documentnumber=1"
                  external
                  buttonClass="gtm-download"
                  documentName="PrescribingInformationES"
                >
                  Descargar
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section tw="lg:pb-6 xl:pb-10">
        <Cta
          data={data}
          styles={tw`min-h-[220px] pt-6 lg:(py-20 min-h-[unset]) xl:(py-32)`}
        >
          <Container tw="relative">
            <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12 h-full)">
              {/* Image */}
              <div tw="relative col-span-full lg:(col-span-4)">
                <GatsbyImage
                  image={getImage(data.ctaImage)}
                  alt=""
                  tw="w-[220px] absolute top-[-20px] right-[-60px] -scale-x-100 lg:(scale-x-[unset] right-0 w-[120%] top-[-100px] left-[-35px]) xl:(top-[-140px] left-[-50px])"
                />
              </div>
              {/* Content */}
              <div tw="col-span-full lg:(col-start-5 col-span-8)">
                <p tw="font-extrabold text-base mb-4 lg:(font-bold text-xl mb-4) xl:(text-27)">
                  Navigating CMV after
                  <br tw="lg:hidden" /> transplant can be challenging,
                </p>
                <p tw="font-light text-base lg:(text-[18px] mb-6) xl:(text-2xl)">
                  but with the right resources,
                  <br tw="lg:hidden" /> you and your care team can
                  <br tw="lg:hidden" /> find the <br tw="hidden lg:block" />
                  treatment plan
                  <br tw="lg:hidden" /> that&apos;s right for you.
                </p>
              </div>
            </div>
          </Container>
        </Cta>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Patient Support and Resources | LIVTENCITY® (maribavir)"
    description="View helpful resources, including tips for talking to your transplant team, and the LIVTENCITY patient brochure. See full Safety and Patient Information."
  />
)

export default IndexPage
